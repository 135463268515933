html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.login {
  display: flex;
  height: 100%;
  flex: 1;

  flex-wrap: wrap;
  align-items: stretch;
}

.login-image {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -999;
  object-fit: cover;
}

.login-gradient-div {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding: 0 1.5rem;
}

.gradient-div {
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.divisor {
  width: 4px;
  background: transparent linear-gradient(180deg, #009af600 0%, #009af6 100%) 0%
    0% no-repeat padding-box;
}

@media (min-width: 992px) {
  .login-gradient-div-lg {
    padding: 0 8rem;
  }
}

.login-div {
  display: flex;
  flex: 1;
  padding: 0 40px;
  flex-direction: column;
  justify-content: center;
}

.pagina-inicial {
  font-weight: 100;
  font-size: 13px;
  letter-spacing: 0.65px;
  color: #009af6;
  padding-left: 40px;
}

.recuperar-conta-div {
  display: flex;
  padding-top: 1.5rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cadastro-div {
  display: flex;
  flex: 1;
  padding: 0 40px;
  flex-direction: column;
  justify-content: center;
}

.padding-container {
  padding: 0px 47px 49px;
}

.login-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: column;
}

.header-login {
  background: transparent linear-gradient(245deg, #009af6 0%, #0069a7 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 0 12px #2c2c2c29;
  border-radius: 3px;
  opacity: 1;
  padding: 2rem 2rem;
  margin-top: -5rem;
}

.padding-gradient-div {
  padding-top: 7vh;
  margin-bottom: 0;
}

.blue-column {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 63px;
  height: 484px;
  background: transparent linear-gradient(186deg, #009af600 0%, #009af6 100%) 0
    0 no-repeat padding-box;
  opacity: 1;
}

.blue-box {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 670px;
  height: 301px;
  background: transparent linear-gradient(242deg, #009af6 0%, #0069a7 100%) 0 0
    no-repeat padding-box;
  opacity: 1;
  z-index: -1;
}

.content-description {
  background-image: url('/src/images/background-image.webp');
  padding: 0 3.75rem 0 0;
}

.card-login {
  padding: 0 3.75rem;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 20px #2c2c2c4d;
  border-radius: 3px;
  opacity: 1;
  min-width: 500px;
  margin: 5.625rem 0 2rem 0;
}

.form-body {
  margin: 2rem 3rem;
  width: 70%;
  margin: 0 auto;
}

.social-networks {
  padding: 0 3rem;
}

.hr-left {
  width: 100%;
  margin: 1rem 2rem 1rem 0;
}

.hr-right {
  width: 100%;
  margin: 1rem 0 1rem 2rem;
}

.mini-card {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 12px #2c2c2c29;
  border-radius: 3px;
  opacity: 1;
  flex-wrap: nowrap !important;
}

.mini-card-active {
  background: #009AF6 0 0 no-repeat padding-box;
  color: #ffffff;
}

.icon-color-svg {
  fill: #b0b0b0;
}

.mini-card-active .icon-color-svg {
  fill: #ffffff;
}

.mudar-recuperacao-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.mudar-recuperacao-text input {
  width: auto;
}

.texto-recuperar-senha-email-sms {
  margin-left: 1rem;
  align-items: flex-end;
  display: flex;
}

.opcoesLogin {
  display: flex;
  width: 100%;
  margin: 0.5rem !important;
}

.recuperar-senha {
  display: flex;
  width: 40%;
  justify-content: flex-start;
}

.manter-logado {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 400px) {
  .manter-logado-text {
    text-align: center;
    width: 100px;
  }
}

@media (max-width: 1200px) {
  .opcoesLogin {
    display: inline;
  }

  .recuperar-senha {
    width: 100%;
    justify-content: center;
  }

  .manter-logado {
    width: 100%;
    justify-content: center;
  }
}

.btn-create-account {
  color: var(--blue) !important;
  background-color: var(--white) !important;
  border-color: var(--blue) !important;
}

.btn-create-account:hover {
  color: var(--white) !important;
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.pointer {
  cursor: pointer;
}

.nome-vazio {
  min-height: 19px;
  background: transparent
    linear-gradient(90deg, #f0f0f0 0%, #dbdbdb 50%, #f0f0f0 100%) 0 0 no-repeat
    padding-box;
  border-radius: 10px;
  opacity: 1;
}

.avatar {
  margin-top: -3rem;
}

.alert {
  top: 0 !important;
}

.alert-transition-enter {
  opacity: 0;
}
.alert-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-transition-exit {
  opacity: 1;
}
.alert-transition-exit-active {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 300ms, transform 300ms;
}

.texto-instrucoes-senha {
  font-size: 8px;
}

.container-password-icon {
  position: relative;
}
.tooltip-password-info {
  position: absolute;
  top: -160px;
  right: -40px;
  width: 200px;
  height: 130px;
  opacity: 0.8;
  background: black;
  padding: 15px;
}

.tooltip-password-info:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 65%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: black;
  border-bottom: 0;
  border-left: 0;
  margin-left: -10px;
  margin-bottom: -20px;
}

.tooltip-password-info > span {
  text-align: center;
  font-size: 10px;
  color: #fff;
}
.icon-password-info {
  position: absolute;
  top: 8px;
  right: 27px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.container-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}
